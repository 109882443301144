import { Directive, Input, ElementRef, Renderer2, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appLazyLoadImage]',
  standalone: false,
})
export class LazyLoadImageDirective implements OnInit {
  @Input() appLazyLoadImage: string;

  public defaultImage: string = '../../../../assets/image/default-img.jpg';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('load') onLoad() {
    if (
      this.el.nativeElement.getAttribute('src') === this.defaultImage ||
      this.el.nativeElement.getAttribute('src') === this.appLazyLoadImage.replace('thumb', 'origin')
    ) {
      return;
    }
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.appLazyLoadImage.replace('thumb', 'origin'));
  }

  @HostListener('error')
  onError() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultImage);
  }

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.appLazyLoadImage);
  }
}
