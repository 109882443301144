import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: 'img[imageError]',
  standalone: false,
})
export class ImageErrorDirective {
  @Input() defaultImage: string = '../../../../assets/image/default-img.jpg';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('error')
  onError() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultImage);
  }
}
