import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: false,
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  @Output() clickOutside: EventEmitter<void> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement: any) {
    if (!this._elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit();
    }
  }
}
