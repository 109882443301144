import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[searchHighlight]',
  standalone: false,
})
export class SearchHighlightDirective implements OnChanges {
  @Input() searchHighlight: string;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchHighlight && this.searchHighlight) {
      setTimeout(() => this.highlightText());
    }
  }

  private highlightText() {
    const text = this.el.nativeElement.innerText;
    const regex = new RegExp(this.searchHighlight, 'gi');
    this.el.nativeElement.innerHTML = text.replace(regex, (match) => `<span class="matched-text">${match}</span>`);
  }
}
