/**
 * HideShownSimpleFormDirective
 *
 * This Angular directive is used to switch between view and edit mode within an article tag.
 * By default, elements with the classes 'edit' and 'text' are shown, while the 'edit-mode' div is hidden.
 * The 'edit' element serves as a trigger to switch the display: clicking on it will hide 'edit' and 'text', and show 'edit-mode' instead.
 * Within 'edit-mode', an 'input' or 'select' element can be updated, and the 'save' element serves as the trigger to revert the display.
 * When 'save' is clicked, or when the 'edit-mode' div loses focus due to a click outside of it, the updated value is emitted if changed, and the view reverts to its original display.
 *
 * @selector 'appHideShownSimpleForm'
 * @outputs 'save': EventEmitter - Emits the new value when saved. Does not emit if the value was not changed.
 *
 * @example
 * <article appHideShownSimpleForm (onSave)="onProfileSave($event)">
 *   <p class="label screen-name" style="min-height: 40px">Screen name</p>
 *   <p class="edit">Edit</p>
 *   <p class="text">{{profile.screenName}}</p>
 *   <div class="edit-mode">
 *     <p class="save">Save</p>
 *     <input type="text" [value]="profile.screenName" [placeholder]="profile.screenName">
 *   </div>
 * </article>
 */

import { Directive, ElementRef, Output, EventEmitter, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appHideShownSimpleForm]',
  standalone: false,
})
export class HideShownSimpleFormDirective implements AfterViewInit, OnDestroy {
  private readonly editModeSelector = '.edit-mode';
  private readonly formControlSelectors = 'input, select';
  private readonly editSelector = '.edit';
  private readonly saveSelector = '.save';
  private readonly textSelector = '.text';

  private editModeElement: HTMLElement;
  private formControls: Array<HTMLInputElement | HTMLSelectElement>;
  private editElement: HTMLElement;
  private saveElement: HTMLElement;
  private textElement: HTMLElement;

  private isEditMode = false;
  private initialFormControlValues: string[] = [];
  private documentClickListener: () => void;

  @Output() save: EventEmitter<string | string[]> = new EventEmitter();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    this.editModeElement = this.el.nativeElement.querySelector(this.editModeSelector);
    this.formControls = Array.from(this.el.nativeElement.querySelectorAll(this.formControlSelectors));
    this.editElement = this.el.nativeElement.querySelector(this.editSelector);
    this.saveElement = this.el.nativeElement.querySelector(this.saveSelector);
    this.textElement = this.el.nativeElement.querySelector(this.textSelector);

    this.renderer.setStyle(this.editModeElement, 'display', 'none'); // hide the edit mode initially

    if (this.editElement) {
      this.renderer.listen(this.editElement, 'click', () => this.switchToEditMode());
    }

    this.renderer.listen(this.saveElement, 'click', () => this.switchToViewMode());

    this.documentClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (this.isEditMode && !this.el.nativeElement.contains(event.target)) {
        this.switchToViewMode();
      }
    });
  }

  ngOnDestroy() {
    this.documentClickListener();
  }

  private switchToEditMode() {
    this.isEditMode = true;
    this.initialFormControlValues = this.formControls.map((control) => control.value);

    this.renderer.setStyle(this.editModeElement, 'display', '');
    this.renderer.setStyle(this.editElement, 'display', 'none');
    this.renderer.setStyle(this.textElement, 'display', 'none');
  }

  private switchToViewMode() {
    this.isEditMode = false;

    this.renderer.setStyle(this.editModeElement, 'display', 'none');
    this.renderer.setStyle(this.editElement, 'display', '');
    this.renderer.setStyle(this.textElement, 'display', '');

    const newFormControlValues = this.formControls.map((control) => {
      if (control instanceof HTMLInputElement || control instanceof HTMLSelectElement) {
        return control.value;
      }
    });
    const isValueChanged = this.initialFormControlValues.some(
      (initialValue, index) => initialValue !== newFormControlValues[index],
    );

    if (isValueChanged) {
      this.save.emit(newFormControlValues.length > 1 ? newFormControlValues : newFormControlValues[0]);
    }
  }
}
